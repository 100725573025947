import React from 'react'
import { FcCheckmark } from 'react-icons/fc'
import './BulletPoint.css'

const BulletPoint = (props) => {
    return (
        <span><FcCheckmark /> { props.title }</span>
    )
}

export default BulletPoint