import React from 'react'
import './Footer.css'
import logo from '../../assets/images/logo.svg'
import migratiepad from '../../assets/images/migratiepad.svg'
import monitoring from '../../assets/images/monitoring.svg'
import sla from '../../assets/images/sla.svg'
import { FcPhone, FcAddressBook, FcGlobe } from 'react-icons/fc'
import ReactMediumImg from 'react-medium-zoom'

const Footer = () => {

    return (
    <>
        <div className="Footer container">
            <div className="row">
                <div className="col-md-4 card">
                    <ReactMediumImg src={ migratiepad } alt="" className="card-img-top img-fluid" />
                    <div className="card-body">
                        <h4>Migratietraject</h4>
                        <p className="card-text">
                            Het verhuizen van een IT omgeving wordt ook wel migreren genoemd.<br />
                            Wij merken vaak dat bedrijven niet precies weten wat een migratie inhoudt en daardoor 
                            het idee hebben dat het problemen oplevert, stilstand betekent en veel geld kost. 
                            Dit is niet nodig. Met een goede voorbereiding voorkomen wij stilstand en 
                            problemen, daarnaast helpen wij je aan kostenbesparing en een verbeterde ICT oplossing. <br /><br />
                            Met de bovenstaande afbeelding wordt verduidelijkt wat wij doen tijdens het migratietraject en wanneer u wel of geen hinder kan verwachten.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 card">
                    <ReactMediumImg src={ sla } alt="" className="card-img-top img-fluid" />
                    <div className="card-body">
                        <h4>Bedrijf Zekerheid SLA</h4>
                        <p className="card-text">
                            SLA, ook wel Service Level Agreement genoemd. In een SLA worden afspraken vastgelegd over het kwaliteitsniveau (service level) dat aan de inbegrepen diensten wordt gekoppeld. <br />
                            Het biedt zekerheid en verduidelijking op de inbegrepen diensten, response tijd en afspraken tussen beide partijen over het kwaliteitsniveau. Want wat kan en mag je verwachten van je IT partner als je geen SLA hebt? Wat heb je wel en niet zichtbaar afgesproken?<br /><br />
                            De SLA Bedrijf Zekerheid van IT Company zorgt voor zekerheid en continuïteit van je onderneming.<br />
                            Met de afspraak 24/7 onbeperkt helpdesk support en de inbegrepen dienst 24/7 realtime monitoring wordt jouw belang bij continuïteit ook ons belang.<br /><br />
                            Met onze SLA is het zowel in jouw als in ons voordeel wanneer problemen blijvend opgelost worden.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 card">
                    <ReactMediumImg src={ monitoring } alt="" className="card-img-top img-fluid" />
                    <div className="card-body">
                        <h4>Realtime Monitoring</h4>
                        <p className="card-text">
                            Weet jij hoeveel het kost als je medewerker een uur niet kan werken? <br />
                            Veel IT bedrijven hebben baat bij storingen om extra uren te kunnen verkopen. <br /><br />
                            Wij werken vanuit jouw belang. Staat je IT stil? Dan staat je bedrijf stil, en dat kost geld! <br />
                            Daarom monitoren wij jouw systemen continu, maar dan ook echt 24/7 en realtime. <br />
                            Hierdoor signaleren we problemen op tijd en kunnen we ze oplossen voordat jij gemerkt hebt dat er een storing is. <br />
                            Handig voor jou, en belangrijk voor ons, want hoe beter wij jouw IT omgeving onderhouden des te minder problemen er zullen zijn. <br /><br />
                            Meer continuïteit voor een vast bedrag per maand, of we nou 4 of 8 uur per week met je IT omgeving bezig zijn.
                            Jij blijft immers dat vaste bedrag per maand betalen.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <div className="BottomBar container">
                <div className="row">
                    <div className="col-md-10">
                        <address>
                            <strong>IT Company B.V.</strong> <br />
                            Zernikelaan 12b<br />
                            9351VA Leek<br />
                            <br />
                            <FcPhone /> <a href="tel:+31852734826">085 - 273 48 26</a><br />
                            <FcAddressBook /> <a href="mailto:sales@itcompany.nl">sales@itcompany.nl</a><br />
                            <FcGlobe /> <a href="https://www.itcompany.nl" target="_blank" rel="noopener noreferrer">www.itcompany.nl</a>
                        </address>
                    </div>
                    <div className="col-md-2 text-right">
                        <img src={logo} className="App-logo" alt="logo" />
                    </div>
                </div>
            </div>
        </footer>
    </>
    )
}

export default Footer