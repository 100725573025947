import React, { useState, useEffect } from 'react'
import axios from 'axios'
import loadImg from '../../assets/images/loading.svg'
import './Form.css'

const Form = () => {
  const initialFormValues = {
    usercount: 5,
    company: '',
    contact: '',
    email: '',
    phone: '',
    checkboxAvg: false
  }

  const initialMailerResponseValues = { 
    message: '', 
    error: false 
  }

  const [formValues, setFormValues] = useState(initialFormValues)
  const [mailerResponse, setMailerResponse] = useState(initialMailerResponseValues)
  const [disableBtn, setDisableBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const { usercount, company, contact, email, phone, checkboxAvg } = formValues
  const { message, error } = mailerResponse

  const handleInputChange = e => {
      const {name, value} = e.target
      setFormValues({...formValues, [name]: value})
  }

  const handleCheck = () => {
    setFormValues({...formValues, checkboxAvg: !formValues.checkboxAvg})
  }

  const reset = e => {
    e.preventDefault()

    setFormValues(initialFormValues)
    setMailerResponse(initialMailerResponseValues)
  }

  const handleSubmit = e => {
    setLoading(true)
    e.preventDefault()

    console.log(loading)

    axios.post('/api/send', {
      usercount: usercount,
      company: company,
      contact: contact,
      email: email,
      phone: phone,
      checkboxAvg: checkboxAvg
    })
    .then((response) => {
      setMailerResponse({ message: response.data.message, error: response.data.error })
      setLoading(false)
    })
    .catch((error) => {
      if (error.response) {
        setMailerResponse({ message: error.response.data.message, error: error.response.data.error })
      } else if (error.request) {
        setMailerResponse({ message: 'Geen verbinding met API', error: true })
      } else {
        setMailerResponse({ message: 'Onbekende fout', error: true })
      }
      
      setLoading(false)
    })

    console.log(loading)
  }
  
  useEffect(() => {
    setDisableBtn(usercount >= 5 && company.length > 0 && contact.length > 0 && email.length > 0 && checkboxAvg === true)
  }, [usercount, company.length, contact.length, email.length, checkboxAvg])

  return (
      <div className="col-md-5 offset-md-1">
        <div className="App-form">
          { loading && 
            <>
              <div className="text-center">
                <img src={ loadImg } alt="" />
                <br /><br />
                <h3>Aan het laden...</h3>
              </div>
            </>
          }
          
          { message &&
            <>
              <div className={ `Message ${error ? 'Error' : 'Success'}` }>
                <h2>{ message }</h2>
              </div>
              <br />
              <button className="btn btn-danger" onClick={reset}>Terug naar formulier</button>
            </>
          }

          { !message && !loading &&
            <>
              <h3>Ja, ik wil meer informatie</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Aantal gebruikers*</label>
                  <input type="number" className="form-control" name="usercount" required={ true } value={ formValues.usercount } onChange={handleInputChange} min="5" />
                </div>
                <div className="form-group">
                  <label>Uw Bedrijfsnaam*</label>
                  <input type="text" className="form-control" name="company" required={ true } value={ formValues.company } onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>Contactpersoon*</label>
                  <input type="text" className="form-control" name="contact" required={ true } value={ formValues.contact } onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>Uw e-mailadres*</label>
                  <input type="email" className="form-control" name="email" required={ true } value={ formValues.email } onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>Uw telefoonnummer</label>
                  <input type="tel" className="form-control" name="phone" value={ formValues.phone } onChange={handleInputChange} />
                </div>
                <br />
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" name="checkboxAvg" required={ true } value={ formValues.checkboxAvg } onChange={handleCheck} />
                  <label className="form-check-label">Ik geef toestemming om de gegevens die ik hier heb ingevuld op te slaan en te gebruiken om contact met mij op te nemen.</label>
                </div>
                <br />
                <div className="text-right">
                  <button type="submit" disabled={!disableBtn} className="btn btn-danger">Prijsindicatie opvragen</button>
                </div>
              </form>
            </>
          }
        </div>
      </div>
  )
}

export default Form