import React from 'react'
import Header from './components/header/Header'
import Usp from './components/usp/Usp'
import Form from './components/form/Form'
import Footer from './components/footer/Footer'
import './App.css'

const App = () => {
  return (
    <>
    <Header />
    <div className="App container">
      <div className="row">
        <div className="col-md-12">
          <div className="App-heading">
            <h1>Bespaar nu kosten met ICT</h1>
            <h5>Werken in de cloud voor de prijs van een kopje koffie per medewerker!</h5>
          </div>
        </div>
      </div>
      <div className="row">
        <Usp />
        <Form />
      </div>
    </div>
    <Footer />
    </>
  )
}

export default App
