import React from 'react'
import { FcCheckmark } from 'react-icons/fc'
import './Usp.css'

const Usp = () => {
    return (
        <div className="col-md-6">
          <div className="App-infographic">
            Veel IT bedrijven zijn niet transparant over IT kosten en zorgen ervoor dat jij het overzicht kwijt raakt. Want weet jij hoeveel je kwijt bent voor software licenties? Wat zit er wel en niet inbegrepen in de SLA? Of weet jij hoeveel het kost als een medewerker niet kan werken door een onnodige IT storing? 
            <br /><br />
            De meeste IT bedrijven werken dan ook nog op basis van uurtje factuurtje en hebben baat bij storingen om extra uren te kunnen verkopen. Herkenbaar?
            <br /><br />
            Wij werken vanuit jouw belang, staat je IT stil! Dan staat je bedrijf stil en dat kost geld.
            <br /><br />
            Afgelopen jaar hebben wij tientallen bedrijven geholpen aan kostenbesparing en een verbeterde IT oplossing. Hoe?
            <br /><br />
            Laat je gegevens achter en ontvang vrijblijvend een prijsindicatie en informatie omtrent de mogelijkheden, hoe we jouw ICT kosten kunnen verlagen en je IT omgeving kunnen verbeteren.
          </div>
          <br />
          <h3>Waarom de cloud van IT Company?</h3>
          <p className="usp">
            <FcCheckmark /> Geen hoge investering in hardware zoals servers<br />
            <FcCheckmark /> Altijd en overal online kunnen werken in een beveiligde snelle omgeving<br />
            <FcCheckmark /> Flexibel opschalen of afschalen in gebruikers<br />
            <FcCheckmark /> Geen onderhoud & 24/7 support<br />
            <FcCheckmark /> Vaste prijs per gebruiker per maand<br />
            <FcCheckmark /> Extra beveiligde omgeving<br />
            <FcCheckmark /> Lagere energierekening<br /><br />
          </p>
        </div>
    )
}

export default Usp