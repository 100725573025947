import React, { useState } from 'react'
import logo from '../../assets/images/logo.svg'
import BulletPoint from './BulletPoint'
import './Header.css'

const Header = () => {
  const initialBulletPoints = [
    "24/7 onbeperkt helpdesk support",
    "Flexibel schalen met gebruikers",
    "Geen hoge investering in hardware zoals servers"
  ]

  const [bulletPoints] = useState(initialBulletPoints)
  
  return (
  <header>
    <div className="container">
      <div className="App-header row">
        <div className="col-md-12">
          <img src={logo} className="App-logo" alt="logo" />
          <div className="App-bar">
            { bulletPoints && bulletPoints.map((bp, index) => (
                <BulletPoint key={index} title={bp} />
            ))}
          </div>
        </div>
      </div>
    </div>
  </header>
  )
}

export default Header